<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div>
      <b-alert :show="showAlerts" dismissible @dismissed="showAlerts=false" variant="warning" class="custom-alert text-orange fw-medium animate__animated animate__shakeX"
        > <i class="uil uil-exclamation-circle font-size-18"></i> Hi {{accessName}}, you have {{admin_overview.pending_for_sign}} factoring applications that require your signature. Please take a moment to review. <router-link :to="{name:'Factoring Application', query :{'requiringMySignature':true}}" class="fw-medium text-orange text-decoration-underline">Check Here.</router-link> </b-alert
      >
    </div>
    <div class="row align-items-end mb-3 mb-lg-2">
      <div class="col">
        <h5 class="font-size-15 mb-3 mb-lg-0">Contract Overview</h5>
      </div>

      <div class="col-md-auto ms-auto mb-0">
        <div class="form-check form-switch form-check-custom mb-0 me-lg-1">
          <input class="form-check-input mb-0" type="checkbox" id="liveUpdate" v-model="liveUpdate" @change="liveUpdates">

          <label class="form-check-label fw-normal mb-0" for="liveUpdate">Live Updates</label>
        </div>
      </div>
    </div>
    <div v-if="loading && !liveUpdateLoading">
      <div class="text-center">
        <div
          class="spinner-border text-primary my-3"
          v-if="loading"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <div
      v-if="
        account_type == 'admin' ||
        account_type == 'merchant' ||
        account_type == 'reseller'
      "
    >
      <div class="row">
        <div
          class="col-12 col-md-6 col-lg-6 col-xl-3"
          v-for="(item, index) in topStatistics"
          :key="index"
        >
          <div class="card overflow-hidden">
            <div class="card-body border-bottom py-2">
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">

                  <p class="mb-2 font-size-16 fw-medium" :class="{ 'text-primary':index==0, 'text-purple':index==1,'text-orange':index==2,'text-success':index==3}" >
                   {{ item.label }}</p>
                  <div v-if="loading">
                      -
                  </div>
                  <h4 class="mb-1 mt-1 font-size-22" v-else>
                    {{ convertThousandMillion(item.data)}}
                  </h4>
                  <p class="text-muted mb-0 font-size-14">Total Contract Today </p>
                </div>
                <div>
                  <div v-if="index == 0">
                    <i
                      class="mdi mdi-chart-line img-fluid animate__animated animate__fadeInRight display-6 text-primary"
                    ></i>
                  </div>
                  <div v-else-if="index == 1">
                    <i
                      class="mdi mdi-chart-bell-curve img-fluid animate__animated animate__fadeInRight display-6 text-purple"
                    ></i>
                  </div>
                  <div v-else-if="index == 2">
                    <i
                      class="mdi mdi-chart-arc img-fluid animate__animated animate__fadeInRight display-6 text-orange"
                    ></i>
                  </div>
                  <div v-else-if="index == 3">
                    <i
                      class="mdi mdi-chart-bell-curve-cumulative img-fluid animate__animated animate__fadeInRight display-6 text-success"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body h-100 py-2">
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading">
                      -
                  </div>
                  <h4 class="mb-1 mt-1 font-size-22" v-else>
                    {{ convertThousandMillion(item.data2) }}
                  </h4>
                  <p class="text-muted mb-0 font-size-14">Total Contract This Month </p>
                </div>
                <div>
                  <div v-if="index == 0">
                    <i
                      class="mdi mdi-chart-line img-fluid animate__animated animate__fadeInRight display-6 text-primary"
                    ></i>
                  </div>
                  <div v-else-if="index == 1">
                    <i
                      class="mdi mdi-chart-bell-curve img-fluid animate__animated animate__fadeInRight display-6 text-purple"
                    ></i>
                  </div>
                  <div v-else-if="index == 2" class="text-end">
                    <i
                      class="mdi mdi-chart-arc img-fluid animate__animated animate__fadeInRight display-6 text-orange"
                    ></i>
                  </div>
                  <div v-else-if="index == 3">
                    <i
                      class="mdi mdi-chart-bell-curve-cumulative img-fluid animate__animated animate__fadeInRight display-6 text-success"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end col-->
      </div>
      <hr class="mb-4 mt-2" v-if="account_type=='admin'">
      <div class="row" v-if="account_type=='admin'">
        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
          <div class="card overflow-hidden">
            <div class="card-body border-bottom py-2">
              <h6 class="mt-1 text-primary animate__animated animate__fadeInLeft">Total New Applicants </h6>
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else>
                    
                      <span data-plugin="counterup">
                          <countTo :startVal="0" :endVal="parseFloat(admin_overview.new_applicants_today)" :duration="2000"></countTo>
                      </span>
                  </h4>
                  <p class="text-muted mb-0 font-size-14">Today</p>
                 
                </div>
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else>
                    <span data-plugin="counterup">
                        <countTo :startVal="0" :endVal="parseFloat(admin_overview.new_applicants_this_months)" :duration="2000"></countTo>
                    </span>
                  </h4>
                  <p class="text-muted mb-0 font-size-14">This Month</p>
                 
                </div>
                <div class="animate__animated animate__fadeInLeft">
                  <i class="uil uil-house-user img-fluid animate__animated animate__fadeInRight display-6 text-primary"
                    ></i>
                </div>
                </div>
              </div>
              <div class="card-body border-bottom py-2">
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else >
                    <span data-plugin="counterup">
                        <countTo :startVal="0" :endVal="parseFloat(admin_overview.total_applicants)" :duration="2000"></countTo>
                    </span>
                  </h4>
                  <p class="text-muted mb-0 font-size-14">Total Applicants</p>
                 
                </div>
                </div>
              </div>
            </div>
            
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
          <div class="card overflow-hidden">
            <div class="card-body border-bottom py-2">
              <h6 class="mt-1 text-purple animate__animated animate__fadeInLeft">Total Factoring Application </h6>
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else>
                    <span data-plugin="counterup">
                        <countTo :startVal="0" :endVal="parseFloat(admin_overview.new_factoring_application_today)" :duration="2000"></countTo>
                    </span>
                  </h4>
                  <p class="text-muted mb-0 font-size-14">Today</p>
                 
                </div>
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else>
                    <span data-plugin="counterup">
                        <countTo :startVal="0" :endVal="parseFloat(admin_overview.new_factoring_application_this_months)" :duration="2000"></countTo>
                    </span>
                  </h4>
                  <p class="text-muted mb-0 font-size-13">This Month</p>
                 
                </div>
                <div class="animate__animated animate__fadeInLeft">
                  <i
                      class="uil uil-file-graph img-fluid animate__animated animate__fadeInRight display-6 text-purple"
                    ></i>
                </div>
                </div>
              </div>
              <div class="card-body border-bottom py-2">
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else>
                    <span data-plugin="counterup">
                        <countTo :startVal="0" :endVal="parseFloat(admin_overview.total_factoring_application)" :duration="2000"></countTo>
                    </span>
                  </h4>
                  <p class="text-muted mb-0 font-size-13">Total Factoring Application</p>
                 
                </div>
                </div>
              </div>
            </div>
            
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
          <div class="card overflow-hidden">
            <div class="card-body border-bottom py-2">
              <h6 class="mt-1 text-orange animate__animated animate__fadeInLeft">Pending Factoring Application</h6>
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else>
                    <span data-plugin="counterup">
                        <countTo :startVal="0" :endVal="parseFloat(admin_overview.pending_factoring_applications)" :duration="2000"></countTo>
                    </span>
                  </h4>
                  <p class="text-muted mb-0 font-size-14">Total Pending Application</p>
                 
                </div>
                <div class="animate__animated animate__fadeInLeft">
                  <i
                      class="mdi mdi-timer-outline img-fluid animate__animated animate__fadeInRight display-6 text-orange"
                    ></i>
                </div>
                </div>
              </div>
              <div class="card-body border-bottom py-2">
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else>
                  {{admin_overview.pending_signing || '0'}}
                  </h4>
                  <p class="text-muted mb-0 font-size-14">Pending Approver Signing</p>
                 
                </div>
                </div>
              </div>
            </div>
            
        </div>
        <div class="col-12 col-md-6 col-lg-6 col-xl-3">
          <div class="card overflow-hidden">
            <div class="card-body border-bottom py-2">
              <h6 class="mt-1 text-success animate__animated animate__fadeInLeft">Approved Factoring Application</h6>
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else>
                    <span data-plugin="counterup">
                        <countTo :startVal="0" :endVal="parseFloat(admin_overview.approved_factoring_application)" :duration="2000"></countTo>
                    </span>
                  </h4>
                  <p class="text-muted mb-0 font-size-14">Total Approved Application</p>
                 
                </div>
                <div class="animate__animated animate__fadeInLeft">
                  <i
                      class="ms-lg-2 mdi mdi-check-circle-outline img-fluid animate__animated animate__fadeInRight display-6 text-success"
                    ></i>
                </div>
                </div>
              </div>
              <div class="card-body border-bottom py-2">
              <div class="d-flex align-items-center">
                <div class="w-100 animate__animated animate__fadeInLeft">
                  <div v-if="loading">
                      <div
                        class="spinner-border spinner-border-sm text-muted my-1"
                        v-if="loading"
                        role="status"
                      >
                      </div>
                  </div>
                  <h4 class="mb-1 mt-1 font-size-18" v-else>
                    <span data-plugin="counterup">
                        <countTo :startVal="0" :endVal="parseFloat(admin_overview.total_factoring_application)" :duration="2000"></countTo>
                    </span>
                  </h4>
                  <p class="text-muted mb-0 font-size-14">Total Factoring Application</p>
                 
                </div>
                </div>
              </div>
            </div>
            
        </div>
        <!-- end col-->
      </div>

      <div class="row mb-3">
        <div class="col-12">
          <div class="card">
            <div class="card-title px-3 py-2 border-bottom">
              <div class="d-flex align-items-center mb-3 mb-md-0">
                <div class="me-2">
                  <i
                    class="uil uil-chart text-primary display-6"
                  ></i>
                </div>
                <div>
                  <h4 class="fw-medium font-size-20 text-dark mb-0">
                    Statistical Overview
                  </h4>
                </div>
                <div class="ms-auto">
                  <div
                    class="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <!-- <input
                      type="radio"
                      class="btn-check btn-check-info"
                      v-model="selectedStatisticsType"
                      name="options"
                      id="1"
                      autocomplete="off"
                      value="1D"
                      :disabled="loading"
                      @change="updateStatistics()"
                    />
                    <label class="btn btn-sm btn-outline-info" for="1"
                      >1D</label
                    > -->
                    <input
                      type="radio"
                      class="btn-check"
                      v-model="selectedStatisticsType"
                      name="options"
                      id="2"
                      autocomplete="off"
                      value="7D"
                      :disabled="loading"
                      @change="updateStatistics()"
                    />
                    <label class="btn btn-sm btn-outline-info" for="2"
                      >7D</label
                    >
                    <input
                      type="radio"
                      class="btn-check"
                      v-model="selectedStatisticsType"
                      name="options"
                      id="3"
                      autocomplete="off"
                      value="1M"
                      :disabled="loading"
                      @change="updateStatistics()"
                    />
                    <label class="btn btn-sm btn-outline-info" for="3"
                      >1M</label
                    >
                    <input
                      type="radio"
                      class="btn-check"
                      v-model="selectedStatisticsType"
                      name="options"
                      id="4"
                      autocomplete="off"
                      value="1Y"
                      :disabled="loading"
                      @change="updateStatistics()"
                    />
                    <label class="btn btn-sm btn-outline-info" for="4"
                      >1Y</label
                    >
                  </div>
                  <!-- <select class="form-select pe-5" v-model="selectedStatisticsType" @change="updateStatistics();" :disabled="loading">
                    <option value="1D">1D</option>
                    <option value="7D">7D</option>
                    <option value="1M">1M</option>
                    <option value="1Y">1Y</option>
                  </select> -->
                </div>
              </div>
              <div>
                <div class="d-flex">
                  <div
                    v-for="(value, index) in StatisticsTotal"
                    :key="index"
                    class="me-3"
                  >
                    <div class="fw-normal text-muted font-size-14">
                      {{ value.label }}
                    </div>
                    <strong class="fw-medium" v-if="!loading">{{
                      convertThousandMillion(value.amount)
                    }}</strong>
                    <strong class="fw-medium" v-else>-</strong>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body py-2 px-1 px-lg-3">
              <div class="text-center my-5"
                  v-if="loading && !liveUpdateLoading">
                <div
                  class="spinner-border text-primary my-5"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="mt-1" v-else-if="loading" style="min-height: 450px">
              </div>
              <div class="mt-1" v-else-if="!loading" style="min-height: 450px">
                <apexchart
                  type="bar"
                  class="apex-charts"
                  dir="ltr"
                  height="450"
                  :options="depositData.chartOptions"
                  :series="depositData.series"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- end row-->
      <div class="row mb-3">
        <div class="col-12">
          <div class="card">
            <div class="card-title px-3 py-2 border-bottom">
              <div class="d-flex align-items-center mb-3 mb-md-0">
                <div class="me-2">
                  <i
                    class="uil uil-chart text-primary display-6"
                  ></i>
                </div>
                <div>
                  <h4 class="fw-medium font-size-20 text-dark mb-0">
                    Project Payment Mode Overview
                  </h4>
                </div>
                <div class="ms-auto">
                  <div
                    class="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <input
                      type="radio"
                      class="btn-check"
                      v-model="selectedStatisticsType2"
                      name="options2"
                      id="22"
                      autocomplete="off"
                      value="7D"
                      :disabled="loading2"
                      @change="getDashboard2()"
                    />
                    <label class="btn btn-sm btn-outline-info" for="22"
                      >7D</label
                    >
                    <input
                      type="radio"
                      class="btn-check"
                      v-model="selectedStatisticsType2"
                      name="options2"
                      id="23"
                      autocomplete="off"
                      value="1M"
                      :disabled="loading2"
                      @change="getDashboard2()"
                    />
                    <label class="btn btn-sm btn-outline-info" for="23"
                      >1M</label
                    >
                    <input
                      type="radio"
                      class="btn-check"
                      v-model="selectedStatisticsType2"
                      name="options2"
                      id="24"
                      autocomplete="off"
                      value="1Y"
                      :disabled="loading2"
                      @change="getDashboard2()"
                    />
                    <label class="btn btn-sm btn-outline-info" for="24"
                      >1Y</label
                    >
                  </div>
                </div>
              </div>
              <div>
                <div class="d-flex">
                  <div
                    v-for="(value, index) in paymentModeTotal"
                    :key="index"
                    class="me-3"
                  >
                    <div class="fw-normal text-muted font-size-14">
                      {{ value.label }}
                    </div>
                    <strong class="fw-medium" v-if="!loading2">{{value.total
                    }}</strong>
                    <strong class="fw-medium" v-else>-</strong>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body py-2 px-1 px-lg-3">
              <div class="text-center my-5"
                  v-if="loading2 && !liveUpdateLoading">
                <div
                  class="spinner-border text-primary my-5"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="mt-1" v-else-if="loading2" style="min-height: 450px">
              </div>
              <div class="mt-1" v-else-if="!loading2" style="min-height: 450px">
                <apexchart
                  type="bar"
                  class="apex-charts"
                  dir="ltr"
                  height="450"
                  :options="paymentModeChart.chartOptions"
                  :series="paymentModeChart.series"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>


    
      <div class="row mb-4" v-if="account_type=='admin'">
        <div class="col-12 col-lg-6 col-xl-6">
          <div class="mb-3">
            <div class="media align-items-center d-flex mb-2">
              <div class="avatar-xs me-2">
                <span class="avatar-title bg-primary rounded-circle text-white"><i class="uil uil-wallet"></i></span></div>
                <div class="media-body"><h5 class="font-size-16 mb-0 text-dark"><small class="font-size-16 d-block">Top 10</small>
                Applicant Current Limit</h5>
                </div>
             </div>
            <ul class="list-group rounded shadow-sm" v-if="loading && !liveUpdateLoading">
              <li class="list-group-item pb-2 placeholder-glow" v-for="(value,index) in [0,1,2]" :key="index">
                <div class="media align-items-center d-flex mb-0">
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                        <span class="placeholder col-8 col-lg-4 rounded"></span></span
                    >
                      <strong class="fw-medium font-size-16 d-block"
                      >
                      <span class="placeholder rounded col-lg-6"></span>
                      </strong
                    >
                    </div>
                </div>
              </li>
              </ul>
              <ul class="list-group rounded shadow-sm" v-else>
              <li class="list-group-item pb-2 " v-for="(value,index) in top_applicant_current_limit" :key="index">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-primary font-size-20"><i class="uil uil-wallet"></i></span></div>
                    <div class="media-body">
                      <h4 class="font-size-14 text-orange mb-1">{{value.applicant}}</h4>
                      <div class="d-flex w-100">
                        <div class="w-100">
                          <span class="text-secondary font-size-14">
                          Pre-Factoring</span
                          >
                            <strong class="fw-medium text-dark font-size-15 d-block"
                            >{{ currencySymbol }}
                            {{
                              convertCurrencyFormat(value.pre_factoring_current_limit || 0)
                            }}</strong
                          >
                        </div>
                        <div class="w-100">
                          <span class="text-secondary font-size-14">
                          Factoring</span
                          >
                            <strong class="fw-medium text-dark font-size-15 d-block"
                            >{{ currencySymbol }}
                            {{
                              convertCurrencyFormat(value.factoring_current_limit || 0)
                            }}</strong
                          >
                        </div>
                      </div>
                      
                    </div>
                </div>
              </li>
              </ul>
              <ul class="list-group rounded shadow-sm" v-if="top_applicant_current_limit.length==0 && !loading">
                <li class="list-group-item py-3">
                  <div class="media align-items-center d-flex mb-0">
                    <div class="avatar-xs me-1">
                      <span class="text-info font-size-20"><i class="uil uil-wallet"></i></span></div>
                      <div class="media-body">
                        <span class="text-secondary font-size-14">
                          No Data Available
                      </span
                      >
                        <strong class="fw-medium text-dark font-size-16 d-block"
                        > </strong
                      >
                      </div>
                  </div>
                </li>
              </ul>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-6">
          <div class="mb-3">
            <div class="media align-items-center d-flex mb-2">
              <div class="avatar-xs me-2">
                <span class="avatar-title bg-dark rounded-circle text-white"><i class="uil uil-file-graph"></i></span></div>
                <div class="media-body"><h5 class="font-size-16 mb-0 text-dark"> <small class="font-size-16 d-block">Top 10</small>
                Contract Amounts</h5>
                </div>
             </div>
            <ul class="list-group rounded shadow-sm" v-if="loading">
              <li class="list-group-item pb-2 placeholder-glow" v-for="(value,index) in [0,1,2]" :key="index">
                <div class="media align-items-center d-flex mb-0">
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                        <span class="placeholder col-8 col-lg-4 rounded"></span></span
                    >
                      <strong class="fw-medium font-size-16 d-block"
                      >
                      <span class="placeholder rounded col-lg-6"></span>
                      </strong
                    >
                    </div>
                </div>
              </li>
            </ul>
            <ul class="list-group rounded shadow-sm" v-else>
              <li class="list-group-item bg-white rounded-top py-3 pb-2" v-for="(value, index) in admin_overview.top_contract_amounts" :key="index">
                
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-primary font-size-22"><i class="uil uil-file-graph"></i></span></div>
                    <div class="media-body">
                      <h4 class="font-size-14 text-orange mb-1">{{value.applicant}}</h4>
                      <div class="d-flex w-100">
                        <div class="w-100">
                          <span class="text-secondary font-size-14 lh-1">
                          Contract Amount</span
                          >
                            <strong class="fw-medium text-dark font-size-15 d-block"
                            >{{ currencySymbol }}
                            {{
                              convertCurrencyFormat(value.contract_amount || 0)
                            }}</strong
                          >
                        </div>
                        <div class="w-100">
                          <span class="text-secondary font-size-14">
                          Contract Tenure</span
                          >
                            <strong class="fw-medium text-dark font-size-15 d-block"
                            v-if="value.contract_tenure!=='-'">
                            {{
                              convertCurrencyFormat(value.contract_tenure || 0)
                            }}</strong
                          >
                          <strong class="fw-medium text-dark font-size-15 d-block"
                            v-else>-</strong
                          >
                        </div>
                      </div>
                      
                </div>
                </div>

                
              </li>
            </ul>

            <ul class="list-group rounded shadow-sm" v-if="admin_overview.top_contract_amounts.length==0 && !loading">
              <li class="list-group-item py-3">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-info font-size-20"><i class="uil uil-file-graph"></i></span></div>
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                        No Data Available
                     </span
                    >
                      <strong class="fw-medium text-dark font-size-16 d-block"
                      > </strong
                    >
                    </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-4 d-none">
          <div class="mb-3">
            <div class="media align-items-center d-flex mb-2">
              <div class="avatar-xs me-2">
                <span class="avatar-title bg-purple rounded-circle text-white"><i class="uil uil-house-user"></i></span></div>
                <div class="media-body"><h5 class="font-size-16 mb-0 text-dark"><small class="font-size-16 d-block">Top 10</small>Settlement Applicants</h5>
                </div>
             </div>
            <ul class="list-group rounded shadow-sm" v-if="loading && !liveUpdateLoading">
              <li class="list-group-item pb-2 placeholder-glow" v-for="(value,index) in [0,1,2]" :key="index">
                <div class="media align-items-center d-flex mb-0">
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                        <span class="placeholder col-8 col-lg-4 rounded"></span></span
                    >
                      <strong class="fw-medium font-size-16 d-block"
                      >
                      <span class="placeholder rounded col-lg-6"></span>
                      </strong
                    >
                    </div>
                </div>
              </li>
             
            </ul>
            <ul class="list-group rounded shadow-sm" v-else>
              <li class="list-group-item pb-2 " v-for="(value,index) in top_settlement_applicant" :key="index">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-purple font-size-22"><i class="uil uil-house-user"></i></span></div>
                    <div class="media-body">
                      <h4 class="font-size-14 text-orange mb-1">{{value.applicant}}</h4>
                      <span class="text-secondary font-size-14">
                     Settlement Amount</span
                    >
                      <strong class="fw-medium text-dark font-size-15 d-block"
                      >{{ currencySymbol }}
                      {{
                        convertCurrencyFormat(value.settlement_amount || 0)
                      }}</strong
                    >
                    </div>
                </div>
              </li>
            </ul>
            <ul class="list-group rounded shadow-sm" v-if="top_settlement_applicant.length==0 && !loading">
              <li class="list-group-item py-3">
                <div class="media align-items-center d-flex mb-0">
                  <div class="avatar-xs me-1">
                    <span class="text-info font-size-20"><i class="uil uil-house-user"></i></span></div>
                    <div class="media-body">
                      <span class="text-secondary font-size-14">
                        No Data Available
                     </span
                    >
                      <strong class="fw-medium text-dark font-size-16 d-block"
                      > </strong
                    >
                    </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Lottie
      :path="'animate/loading-168.json'"
      :title="'Loading'"
      :info="'Please wait...'"
      :show="showLottie"
      ref="lottieFunc"
    />
    <Common ref="commonFunc" />
  </Layout>
</template>
<script>
import countTo from "vue-count-to";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
export const equal2A = (value) => {
  return value !== "YOUR_API_ID";
};
export const equal2B = (value) => {
  return value !== "YOUR_API_HASH";
};

/**
 * Settings page
 */
const PageTitle = "Dashboard";
const CountryDefault = { name: "All Countries", value: "-", status: "" };
export default {
  components: {
    Layout,
    PageHeader,
    Lottie,
    Common,
    countTo
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    };
  },
  data() {
    return {
      accessToken: "",
      accessEmail: "",
      accessPhone: "",
      accessUsername: "",
      accessName: "",
      showLottie: false,
      account_type: "",
      title: "",
      items: [
        {
          text: "IKHLAS",
          to: "/",
        },
        {
          text: "",
          active: true,
        },
      ],
      formData: {
        apiID: "YOUR_API_ID",
        apiHash: "YOUR_API_HASH",
      },
      submitted: false,
      loading: true,
      loading2: true,
      loading3: true,
      liveUpdateIntervalId: null,
      liveUpdate:false,
      liveUpdateLoading:false,
      buttonLoading: [false, false, false],
      currencySymbol: "MYR",
      selectedCountry: CountryDefault,
      countryList: [CountryDefault],
      selectedPg: {},
      pgList: [],
      inactivePgList: [],
      contractDetails: [],
      transactionFee: "-",
      selectedStatisticsType: "1M",
      selectedStatisticsType2: "1M",
      StatisticsTotal: [],
      paymentModeTotal: [],
      topStatistics: [
        {
          label: "Recurring",
          data: "",
          data2: "",
        },
        {
          label: "One Off",
          data: "",
          data2: "",
        },
        {
          label: "Milestone/Staggered",
          data: "",
          data2: "",
        },
        {
          label: "Progress",
          data: "",
          data2: "",
        }
      ],
      admin_overview:{
        new_applicants_today:0,
        new_applicants_this_months:0,
        total_applicants:0,
        new_factoring_application_today:0,
        new_factoring_application_this_months:0,
        total_factoring_application:0,
        pending_factoring_applications:0,
        pending_signing:"",
        approved_factoring_application:0,
        top_contract_amounts:[],
        last_10_transaction_processing_time:[],
        pending_for_sign:0
      },
      top_settlement_applicant:[],
      top_applicant_current_limit:[],
      showAlerts:false,
      depositData: {
        series: [
          {
            name: "Desktops",
            data: [
              /* your data values */
            ],
          },
        ],
        chartOptions: {
          chart: {
            type: "bar", // Add the chart type property
          },
          // Rest of your chart options
        },
      },
      paymentModeChart: {
        series: [
          {
            name: "",
            data: [
              /* your data values */
            ],
          },
        ],
        chartOptions: {
          chart: {
            type: "bar", // Add the chart type property
          },
          // Rest of your chart options
        },
      },
      payinMerchants:[],
      payoutMerchants:[]
    };
  },
  validations: {
    formData: {
      apiID: {
        required,
        equal2A,
      },
      apiHash: {
        required,
        equal2B,
      },
    },
  },
  middleware: "authentication",
  async mounted() {
    await this.reload();
    this.title = PageTitle;
    this.items[1].text = PageTitle;
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    this.accessToken = this.$refs.commonFunc.getToken();
    this.accessUsername = this.$refs.commonFunc.getUsername();
    // this.getCountries();
    this.getDashboard();
    this.getDashboard2();
    // if (this.account_type == "merchant") {
    //   //this.getMerchantData();
    //   this.getDashboard();
    // }
  },
  created() {
    this.liveUpdate = false;
    this.liveUpdates()
  },
  beforeDestroy() {
    // Clear the interval when the component is about to be destroyed
    if (this.liveUpdateIntervalId) {
      clearInterval(this.liveUpdateIntervalId);
    }
  },
  methods: {
    liveUpdates() {
      this.liveUpdateIntervalId = setInterval(() => {
        if (this.liveUpdate) {
          console.log('Updating');
          this.getDashboard();
          this.getDashboard2();
          this.liveUpdateLoading = true;
        }
      }, 10000);
    },
    
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value);
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc
          .convertCurrencyFormat(value)
          .replaceAll(".00", "");
      }
    },
    convertThousandMillion(value) {
      // Assuming this.transactionLimit is defined in your component's data
      return this.$refs.commonFunc
        .convertThousandMillion(parseInt(value))
        .replaceAll(".00", "");
    },
    updateStatistics() {
      console.log("updateStatistics");
      this.getDashboard();
      //this.getDashboard2();
    },
    async getDashboard() {
      console.log("getDashboard")
      this.$Progress.start();
      this.loading = true;
      // try {
      //   const result = await this.test();
      //   console.log(result); // You can do something with the result if needed
      // } catch (error) {
      //   console.error("Error in test function:", error);
      // } finally {
      //   console.log("end");
      // }
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("statisticsType", this.selectedStatisticsType);
      axios({
        method: "post",
        url:  appConfig.APIHost + 'controller/admin/getStatisticData',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData);

            this.admin_overview={
              new_applicants_today:0,
              new_applicants_this_months:0,
              total_applicants:0,
              new_factoring_application_today:0,
              new_factoring_application_this_months:0,
              total_factoring_application:0,
              pending_factoring_applications:0,
              pending_signing:"",
              approved_factoring_application:0,
              total_transactions:0,
              success_rate:"",
              top_contract_amounts:[],
              pending_for_sign: 0,//from logged account user
            }

            this.admin_overview={
              new_applicants_today: resData.data.new_applicants_today,
              new_applicants_this_months: resData.data.new_applicants_this_months,
              total_applicants:  resData.data.total_applicants,
              new_factoring_application_today: resData.data.new_factoring_application_today,
              new_factoring_application_this_months: resData.data.new_factoring_application_this_months,
              total_factoring_application: resData.data.total_factoring_application,
              pending_factoring_applications: resData.data.pending_factoring_applications,
              pending_signing: resData.data.pending_signing,
              approved_factoring_application: resData.data.approved_factoring_application,
              total_transactions: resData.data.total_transactions,
              success_rate: resData.data.success_rate,
              top_contract_amounts: resData.data.top_contract_amounts,
              pending_for_sign: resData.data.pending_for_sign
            }
            this.top_settlement_applicant = resData.data.top_settlement_applicant;
            this.top_applicant_current_limit = resData.data.top_applicant_current_limit;

            this.topStatistics = [
            {
              label: "Recurring",
              data: resData.data.recurring_today,
              data2: resData.data.recurring_month,
            },
            {
              label: "One Off",
              data: resData.data.one_off_today,
              data2: resData.data.one_off_month,
            },
            {
              label: "Milestone/Staggered",
              data: resData.data.milestone_staggered_today,
              data2: resData.data.milestone_staggered_month,
            },
            {
              label: "Progress",
              data: resData.data.progress_today,
              data2: resData.data.progress_month,
            }
            ];

            if (parseInt(resData.data.pending_for_sign)>0){
              this.showAlerts=true;
              Swal.fire({
                icon: "warning",
                title: "Pending Signature Required",
                html: `<p>Hi ${this.accessName}, you have <strong class="text-orange fw-medium">${this.admin_overview.pending_for_sign} </strong>factoring applications that require your signature. </p><p class="mb-0">Please take a moment to review it. </p>`,
                confirmButtonColor: "#222",
                confirmButtonText: "View",
                showCancelButton: true,
                }).then((value) => {
                  if (value.isConfirmed) {
                    this.$router.push({name:'Factoring Application', query :{'requiringMySignature':true}});
                  }
                });
            }else{
              this.showAlerts=false;
            }
            
            // if (this.selectedStatisticsType == "7D") {
            //   // Define the date range and initialize the arrays
            //   const startDate = new Date();
            //   startDate.setDate(startDate.getDate() - 6); // 30 days ago

            //   // Generate random data for each day within the date range
            //   for (let i = 0; i < 7; i++) {
            //     const currentDate = new Date(startDate);
            //     currentDate.setDate(currentDate.getDate() + i);

            //     // Format the date as 'dd MMM' (e.g., '01 Jun', '02 Jun', etc.)
            //     const formattedDate = currentDate.toLocaleDateString("en-US", {
            //       day: "2-digit",
            //       month: "short",
            //     });
            //     dateArray.push(formattedDate);

            //     // Generate random deposit amount and merchant fee
            //     const randomDeposit = Math.floor(
            //       Math.random() * 100000
            //     ).toString();
            //     const profitPercentage = 55 / 100; // 2.5% merchant fee
            //     const randomFee = Math.floor(
            //       parseFloat(randomDeposit) * profitPercentage
            //     ).toString();
            //     depositAmount.push(randomDeposit);
            //     var randomFeeMode = Math.random() < 0.5 ? 0 : randomFee;
            //     profit.push(randomFeeMode);
            //   }
             
            //   this.StatisticsTotal = [
            //     {
            //       label: "Total Project Income",
            //       amount: "801000",
            //     },
            //     {
            //       label: "Total Profit",
            //       amount: "122000",
            //     },
            //   ];
            // }
            // if (this.selectedStatisticsType == "1M") {
            //   const today = new Date();
            //   const currentYear = today.getFullYear();
            //   const currentMonth = today.getMonth(); // Returns 0-based index (0 for January, 1 for February, etc.)
            //   const currentDay = today.getDate();
            //   const daysInMonth = new Date(
            //     currentYear,
            //     currentMonth + 1,
            //     0
            //   ).getDate();

            //   const startDate = new Date(currentYear, currentMonth, 1);
            //   const endDate = new Date(currentYear, currentMonth, currentDay);

            //   // Generate random data for each day up to today
            //   for (
            //     let currentDate = new Date(startDate);
            //     currentDate <= endDate;
            //     currentDate.setDate(currentDate.getDate() + 1)
            //   ) {
            //     // Format the date as 'dd MMM' (e.g., '01 Aug', '02 Aug', etc.)
            //     const formattedDate = currentDate.toLocaleDateString("en-US", {
            //       day: "2-digit",
            //       month: "short",
            //     });
            //     dateArray.push(formattedDate);

            //     // Generate random deposit amount and merchant fee
            //     const randomDeposit = Math.floor(
            //       Math.random() * 100000
            //     ).toString();
            //     const profitPercentage = 55 / 100; // 2.5% merchant fee
            //     const randomFee = Math.floor(
            //       parseFloat(randomDeposit) * profitPercentage
            //     ).toString();
            //     depositAmount.push(randomDeposit);
            //     const randomFeeMode = Math.random() < 0.5 ? 0 : randomFee;
            //     profit.push(randomFeeMode);
            //   }

            //   // Fill in the remaining days with zero values
            //   for (let i = currentDay + 1; i <= daysInMonth; i++) {
            //     //console.log(i + ' ' + parseInt(currentMonth + 1))
            //     const day = this.convertDate(
            //       `${i} ${parseInt(currentMonth + 1)}`
            //     );

            //     dateArray.push(day);
            //     depositAmount.push("0");
            //     profit.push("0");
            //   }
            //   this.StatisticsTotal = [
            //     {
            //       label: "Total Project Income",
            //       amount: "801000",
            //     },
            //     {
            //       label: "Total Profit",
            //       amount: "122000",
            //     },
            //   ];
            // }
            // if (this.selectedStatisticsType == "1D") {
            //   // Generate random data for each day within the date range
            //   for (let i = 0; i < 24; i++) {
            //     dateArray.push(i + 1);

            //     // Generate random deposit amount and merchant fee
            //     const randomDeposit = Math.floor(
            //       Math.random() * 100000
            //     ).toString();
            //     const profitPercentage = 55 / 100; // 2.5% merchant fee
            //     const randomFee = Math.floor(
            //       parseFloat(randomDeposit) * profitPercentage
            //     ).toString();
            //     depositAmount.push(randomDeposit);
            //     const randomFeeMode = Math.random() < 0.5 ? 0 : randomFee;
            //     profit.push(randomFeeMode);
            //   }
            //   this.StatisticsTotal = [
            //     {
            //       label: "Total Project Income",
            //       amount: "801000",
            //     },
            //     {
            //       label: "Total Profit",
            //       amount: "122000",
            //     },
            //   ];
            // }
            // if (this.selectedStatisticsType == "1Y") {
            //   const today = new Date();
            //   const currentMonth = today.getMonth();
            //   const currentYear = today.getFullYear();
            //   for (let i = 0; i < 12; i++) {
            //     const months = [
            //       "Jan",
            //       "Feb",
            //       "Mar",
            //       "Apr",
            //       "May",
            //       "Jun",
            //       "Jul",
            //       "Aug",
            //       "Sep",
            //       "Oct",
            //       "Nov",
            //       "Dec",
            //     ];

            //     // Set deposit and merchant fee to 0 for months that are not the current month

            //     console.log(currentMonth);
            //     if (i < parseInt(currentMonth + 1)) {
            //       dateArray.push(months[i] + " " + currentYear);
            //       // Push deposit amount and merchant fee
            //       // Generate random deposit amount and merchant fee
            //       const randomDeposit = Math.floor(
            //         Math.random() * 100000
            //       ).toString();
            //       const profitPercentage = 55 / 100; // 2.5% merchant fee
            //       const randomFee = Math.floor(
            //         parseFloat(randomDeposit) * profitPercentage
            //       ).toString();
            //       depositAmount.push(randomDeposit);
            //       const randomFeeMode = Math.random() < 0.5 ? 0 : randomFee;
            //       profit.push(randomFeeMode);
            //     } else {
            //       dateArray.push(months[i] + " " + currentYear);
            //       depositAmount.push("0");
            //       profit.push("0");
            //     }
            //   }
            //   this.StatisticsTotal = [
            //     {
            //       label: "Total Project Income",
            //       amount: "801000",
            //     },
            //     {
            //       label: "Total Profit",
            //       amount: "122000",
            //     },
            //   ];
            // }

            this.StatisticsTotal = [
              {
                label: "Total Project Income",
                amount: resData.data.total_project_income,
              },
              {
                label: "Total Profit",
                amount: resData.data.total_profit,
              }
            ];
            const dateArray = resData.data.date;
            const projectIncome = resData.data.project_income;
            const profit = resData.data.profit;
            this.depositData = {
              series: [
                {
                  name: "Total Project Income",
                  data: projectIncome,
                },
                {
                  name: "Total Profit",
                  data: profit,
                },
              ],
              chartOptions: {
                chart: {
                  height: 420,
                  type: 'bar',
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                },
                colors: ["#242971", "#12b161"],
                stroke: {
                  width: [2, 2],
                  colors:  ["#242971", "#12b161"],
                  curve: "smooth",
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    borderRadius: 3,
                    columnWidth: '85%',
                    //endingShape: 'rounded'
                  }
                },
                dataLabels: {
                  enabled: false
                },
                fill: {
                  opacity: 0.7
                },
                title: {
                  text: "Statistical Overview",
                  align: "left",
                },
                tooltip: {
                  shared: true,
                  intersect: false,
                  y: {
                    formatter: (y) => {
                      if (typeof y !== "undefined") {
                        return `${this.convertCurrencyFormat(y, false)}`;
                      }
                      return y;
                    },
                  },
                },
                grid: {
                  borderColor: "#f1f1f1",
                },
                labels: dateArray,
                markers: {
                  size: 3,
                  colors: "#ffffff",
                  strokeColors: ["#9e2988", "#462f94"],
                  strokeWidth: 1,
                  hover: {
                    size: undefined,
                    sizeOffset: 2,
                  },
                },
                yaxis: {
                  title: {
                    text: "Project Amount",
                  },
                  forceNiceScale:true,
                  min: 0,
                  labels: {
                      formatter: (y) =>  {
                        if (typeof y !== "undefined") {
                          return `${this.convertCurrencyFormat(parseFloat(y),false)}`;
                        }
                        return y;
                        },
                  },
                },
                legend: {
                  position: "top",
                  horizontalAlign: "right",
                  floating: true,
                  offsetY: -25,
                  offsetX: -5,
                },
              },
            };
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    async getDashboard2() {
      console.log("getDashboard2")
      this.$Progress.start();
      this.loading2 = true;
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("statisticsType", this.selectedStatisticsType2);
      axios({
        method: "post",
        url:  appConfig.APIHost + 'controller/admin/getPaymentModeData',
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          var resData = response.data;
          if (resData.status == 200) {
            console.log(resData);

            // var date = [];
            // var totaleP = [];
            // var totalnonEP = [];
            // var countTotaleP = 0
            // var countTotalnonEP = 0
            
            // if (this.selectedStatisticsType2 == "7D") {
            //   // Define the date range and initialize the arrays
            //   const startDate = new Date();
            //   startDate.setDate(startDate.getDate() - 6); // 30 days ago

            //   // Generate random data for each day within the date range
            //   for (let i = 0; i < 7; i++) {
            //     const currentDate = new Date(startDate);
            //     currentDate.setDate(currentDate.getDate() + i);

            //     // Format the date as 'dd MMM' (e.g., '01 Jun', '02 Jun', etc.)
            //     const formattedDate = currentDate.toLocaleDateString("en-US", {
            //       day: "2-digit",
            //       month: "short",
            //     });
            //     date.push(formattedDate);

            //     // Generate random deposit amount and merchant fee
            //     const randomDeposit = Math.floor(
            //       Math.random() * 10
            //     ).toString();
            //     const profitPercentage = 80 / 100; // 2.5% merchant fee
            //     const randomFee = Math.floor(
            //       parseFloat(randomDeposit) * profitPercentage
            //     ).toString();
            //     totaleP.push(randomDeposit);
            //     var randomFeeMode = Math.random() < 0.2 ? 0 : randomFee;
            //     totalnonEP.push(randomFeeMode);
            //     countTotaleP = parseFloat(countTotaleP) + parseFloat(randomDeposit);
            //     countTotalnonEP =  parseFloat(countTotalnonEP) + parseFloat(randomFeeMode)
            //   }
             
            //   this.paymentModeTotal = [
            //     {
            //       label: "ePerolehan (eP)",
            //       total: countTotaleP,
            //     },
            //     {
            //       label: "Non-ePerolehan (Non-eP)",
            //       total: countTotalnonEP,
            //     },
            //   ];
            // }
            // if (this.selectedStatisticsType2 == "1M") {
            //   const today = new Date();
            //   const currentYear = today.getFullYear();
            //   const currentMonth = today.getMonth(); // Returns 0-based index (0 for January, 1 for February, etc.)
            //   const currentDay = today.getDate();
            //   const daysInMonth = new Date(
            //     currentYear,
            //     currentMonth + 1,
            //     0
            //   ).getDate();

            //   const startDate = new Date(currentYear, currentMonth, 1);
            //   const endDate = new Date(currentYear, currentMonth, currentDay);

            //   // Generate random data for each day up to today
            //   for (
            //     let currentDate = new Date(startDate);
            //     currentDate <= endDate;
            //     currentDate.setDate(currentDate.getDate() + 1)
            //   ) {
            //     // Format the date as 'dd MMM' (e.g., '01 Aug', '02 Aug', etc.)
            //     const formattedDate = currentDate.toLocaleDateString("en-US", {
            //       day: "2-digit",
            //       month: "short",
            //     });
            //     date.push(formattedDate);

            //     // Generate random deposit amount and merchant fee
            //     const randomDeposit = Math.floor(
            //       Math.random() * 100
            //     ).toString();
            //     const profitPercentage = 90 / 100; // 2.5% merchant fee
            //     const randomFee = Math.floor(
            //       parseFloat(randomDeposit) * profitPercentage
            //     ).toString();
            //     totaleP.push(randomDeposit);
            //     const randomFeeMode = Math.random() < 0.2 ? 0 : randomFee;
            //     totalnonEP.push(randomFeeMode);
            //     countTotaleP = parseFloat(countTotaleP) + parseFloat(randomDeposit);
            //     countTotalnonEP =  parseFloat(countTotalnonEP) + parseFloat(randomFeeMode)
            //   }

            //   // Fill in the remaining days with zero values
            //   for (let i = currentDay + 1; i <= daysInMonth; i++) {
            //     //console.log(i + ' ' + parseInt(currentMonth + 1))
            //     const day = this.convertDate(
            //       `${i} ${parseInt(currentMonth + 1)}`
            //     );

            //     date.push(day);
            //     totaleP.push("0");
            //     totalnonEP.push("0");
            //   }
            
            //   this.paymentModeTotal = [
            //     {
            //       label: "ePerolehan (eP)",
            //       total: countTotaleP,
            //     },
            //     {
            //       label: "Non-ePerolehan (Non-eP)",
            //       total: countTotalnonEP,
            //     },
            //   ];
            // }
            // if (this.selectedStatisticsType2 == "1Y") {
            //   const today = new Date();
            //   const currentMonth = today.getMonth();
            //   const currentYear = today.getFullYear();
            //   for (let i = 0; i < 12; i++) {
            //     const months = [
            //       "Jan",
            //       "Feb",
            //       "Mar",
            //       "Apr",
            //       "May",
            //       "Jun",
            //       "Jul",
            //       "Aug",
            //       "Sep",
            //       "Oct",
            //       "Nov",
            //       "Dec",
            //     ];

            //     // Set deposit and merchant fee to 0 for months that are not the current month

            //     console.log(currentMonth);
            //     if (i < parseInt(currentMonth + 1)) {
            //       date.push(months[i] + " " + currentYear);
            //       // Push deposit amount and merchant fee
            //       // Generate random deposit amount and merchant fee
            //       const randomDeposit = Math.floor(
            //         Math.random() * 1000
            //       ).toString();
            //       const profitPercentage = 85 / 100; // 2.5% merchant fee
            //       const randomFee = Math.floor(
            //         parseFloat(randomDeposit) * profitPercentage
            //       ).toString();
            //       totaleP.push(randomDeposit);
            //       const randomFeeMode = Math.random() < 0.2 ? 0 : randomFee;
            //       totalnonEP.push(randomFeeMode);
            //       countTotaleP = parseFloat(countTotaleP) + parseFloat(randomDeposit);
            //       countTotalnonEP =  parseFloat(countTotalnonEP) + parseFloat(randomFeeMode)
            //     } else {
            //       date.push(months[i] + " " + currentYear);
            //       totaleP.push("0");
            //       totalnonEP.push("0");
            //     }
            //   }
            //   this.paymentModeTotal = [
            //     {
            //       label: "ePerolehan (eP)",
            //       total: countTotaleP,
            //     },
            //     {
            //       label: "Non-ePerolehan (Non-eP)",
            //       total: countTotalnonEP,
            //     },
            //   ];
            // }

            const date = resData.data.date;
            const totaleP = resData.data.eP;
            const totalnonEP = resData.data.non_eP;
            const countTotaleP = resData.data.total_eP;
            const countTotalnonEP = resData.data.total_non_eP;
            
            this.paymentModeTotal = [
              {
                label: "ePerolehan (eP)",
                total: countTotaleP,
              },
              {
                label: "Non-ePerolehan (Non-eP)",
                total: countTotalnonEP,
              },
            ];

            this.paymentModeChart = {
              series: [
                {
                  name: "ePerolehan (eP)",
                  data: totaleP,
                },
                {
                  name: "Non-ePerolehan (Non-eP)",
                  data: totalnonEP,
                },
              ],
              chartOptions: {
                chart: {
                  height: 420,
                  type: 'bar',
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                },
                colors: ["#274ba2", "#ff8700"],
                stroke: {
                  width: [2, 2],
                  colors:  ["#274ba2", "#ff8700"],
                  curve: "smooth",
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    borderRadius: 3,
                    columnWidth: '85%',
                    //endingShape: 'rounded'
                  }
                },
                dataLabels: {
                  enabled: false
                },
                fill: {
                  opacity: 0.7
                },
                title: {
                  text: "Statistical Overview",
                  align: "left",
                },
                tooltip: {
                  shared: true,
                  intersect: false,
                  y: {
                    formatter: (y) => {
                      if (typeof y !== "undefined") {
                        return `${this.convertCurrencyFormat(y, false)}`;
                      }
                      return y;
                    },
                  },
                },
                grid: {
                  borderColor: "#f1f1f1",
                },
                labels: date,
                markers: {
                  size: 3,
                  colors: "#ffffff",
                  strokeColors: ["#9e2988", "#462f94"],
                  strokeWidth: 1,
                  hover: {
                    size: undefined,
                    sizeOffset: 2,
                  },
                },
                yaxis: {
                  title: {
                    text: "Total of Payment Mode",
                  },
                  forceNiceScale:true,
                  min: 0,
                  labels: {
                      formatter: (y) =>  {
                        if (typeof y !== "undefined") {
                          return `${y}`;
                        }
                        return y;
                        },
                  },
                },
                legend: {
                  position: "top",
                  horizontalAlign: "right",
                  floating: true,
                  offsetY: -25,
                  offsetX: -5,
                },
              },
            };
          } else if (resData.status == 440) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
            localStorage.clear();
            this.$router.push({
              path: "/login",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              html: `${resData.message}.`,
              confirmButtonColor: "#222",
              confirmButtonText: this.$t("siteLang.Done"),
            });
          }
          this.loading2 = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.loading2 = false;
          this.$Progress.finish();
          //Swal.fire("Error", error, "error");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html: `${error}.`,
            confirmButtonColor: "#222",
            confirmButtonText: this.$t("siteLang.Done"),
          });
        });
    },
    async test() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 5000);
      });
    },
    convertDate(dateStr) {
      try {
        const parts = dateStr.split(" ");
        const day = parts[0];
        const monthNumber = parseInt(parts[1]);

        if (isNaN(monthNumber) || monthNumber < 1 || monthNumber > 12) {
          throw new Error("Invalid month");
        }

        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const monthAbbreviation = months[monthNumber - 1];

        return day + " " + monthAbbreviation;
      } catch (error) {
        return "Invalid date format";
      }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          console.log(data);
          this.accessName = data.full_name;
          this.account_type = data.account_type;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  },
};
</script>
<style scoped>
.avatar-title i{font-size: 18px;}
</style>